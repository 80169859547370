/**
 * @file
 * Provides MicroModal loader.
 */

 import './micromodal.css';

(($, context) => {
    //Defines behavior of media embeds, and keyboard events
    class ModalBehavior {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.$close = $("[data-micromodal-close]", this.$node);
            this.$trigger = $("[data-micromodal-trigger]");
        } 

        setEventBindings() {
            MicroModal.init({
                onShow: this.onShow.bind(this),
                onClose: this.onClose.bind(this),
                disableScroll: true
            });

            if (this.$node.data("micromodal-init-open") == true) {
                MicroModal.show(this.$node.prop("id"));
            }

            this.$trigger.on("keydown", (event) => {
                var keycode = event.keyCode ? event.keyCode : event.which;
                if (keycode == "13" || keycode == "32") {
                    event.preventDefault();
                    let modalId = $(event.currentTarget).data(
                        "micromodal-trigger"
                    );
                    MicroModal.show(modalId);
                }
            });

            $("a").on("click", (e) => {
                let target = $(e.currentTarget);
                let href = target.attr("href");
                if (
                    href.charAt(0) === "#" &&
                    href.length > 1 &&
                    $(href).is("[data-micromodal]")
                ) {
                    e.preventDefault();
                    href = href.substr(1);
                    MicroModal.show(href);
                }
            });
        }

        onShow() {
            $("[data-slick]").trigger("slick-reinit");
        }
        onClose() {
            this.stopMediaEmbed()
        }

        stopMediaEmbed() {
            // best way I could find to stop the embeded video players
            let $embed = this.$node.find("iframe");
            if($embed.length){
                let source = $embed.attr('src');
                let sourceDoc = $embed.attr('srcdoc');
                $embed.attr('src', '').attr('srcdoc', '').attr('src', source).attr('srcdoc', sourceDoc);
            }

            let $video = this.$node.find("video");
            if($video.length) {
                $video.trigger('pause');
            }

        }

        consoleModalIds() {
            console.log(`Modal ID: #${this.node.id}`);
        }

        init() {
            this.setEventBindings();
            // this.consoleModalIds();
        }
    }

    $("[data-micromodal]", context).map((index, node) => {
        new ModalBehavior(node, context).init();
    });
})(jQuery, jQuery(document));
